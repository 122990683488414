import * as React from 'react'
import '../reset.css'

import { useStaticQuery, graphql } from 'gatsby'

import NavBar from './navbar.js'
import Footer from './footer.js'

import { main } from './layout.module.css'


const Layout = ({children}) => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<main className={main}>
			<title>{data.site.siteMetadata.title}</title>
			<NavBar />
			{children}
			<Footer />
		</main>
	)
}

export default Layout
