import * as React from 'react'

import {
	footer,
	copyright,
} from './footer.module.css'


const Footer = () => {
	return (
		<footer className={footer}>
			<span className={copyright}>&copy; Direct Control Technologies Inc. {new Date().getFullYear()}</span>
		</footer>
	)
}

export default Footer
