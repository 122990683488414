import * as React from 'react'
import { Link } from 'gatsby'

import {
	navBar,
	navLogo,
	navLogoItem,
	navLinks,
	navLinkItem,
	navLinkText,
} from './navbar.module.css'


const NavBar = () => {
	return (
		<nav className={navBar}>
			<ul>
				<li className={navLogoItem}>
					<Link to="/" className={navLogo}>DCT</Link>
				</li>
			</ul>
			<ul className={navLinks}>
				<li className={navLinkItem}>
					<Link to="#contact" className={navLinkText}>Contact</Link>
				</li>
			</ul>
		</nav>
	)
}

export default NavBar
